import * as $ from 'jquery';
import { UiBuilder } from './uibuilder';

export class ExportFileRequest {
  ExportType: string;
  Filter?: string;
  BookmarkState?: string;
  PageName?: string;
    Pages?: string[] = ["asd"];    
    PageParamsProperties?: PageParamsProps[];
    // creado para diferenciar 
    VisualName?: string;
}

export class PageParamsProps {
    
    Name?: string;
    DisplayName?: string;
    
}


export class Exporter {
    static apiRoot: string = "/api/";
  
  static ExportFile = async (ExportRequest: ExportFileRequest, queryString: String): Promise<void> => {
    var restUrl: string = this.apiRoot + "ExportFile/" + ((queryString !== "") ? queryString : "");

    var oReq = new XMLHttpRequest();
    oReq.open("POST", restUrl, true);
    oReq.responseType = "blob";

  
    oReq.onload = function (oEvent) {

      var blobData = oReq.response;
      var blob = new Blob([blobData], { type: oReq.response.type });

      var filename = "";
      var disposition = oReq.getResponseHeader('Content-Disposition');
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
        var url = window.URL || window.webkitURL;
        try {
            var link = url.createObjectURL(blob);
            var a: JQuery = $("<a />");
            a.attr("download", filename);
            a.attr("href", link);
            $("body").append(a);
            a[0].click();
            a.remove();
        } catch {
            UiBuilder.DisplayError("Ocurrió un error al intentar descargar el reporte. Intente de nuevo más tarde.");
        } finally {
            UiBuilder.DismissNotification(["btn-exportar-pdf", "btn-exportar-png", "btn-exportar-pptx", "btn-pantallaCompleta", "btn-refrescar"])
        }
    };


    oReq.setRequestHeader("Content-Type", "application/json");
      
    await oReq.send(JSON.stringify(ExportRequest));
  }

}


import * as powerbi from "powerbi-client";
import * as models from "powerbi-models";

import { UiBuilder } from './uibuilder';

import { Exporter, ExportFileRequest } from './exporter';


require('powerbi-models');
require('powerbi-client');

export class ViewModel {
  reportId: string;
  embedUrl: string;
  paginatedReportId: string;
  paginatedEmbedUrl: string;
  turduckenReportId: string;
  turduckenEmbedUrl: string;
  token: string
}

export class Playbook {

    public static EmbedPowerBiReport(reportContainer: HTMLElement, viewModel: ViewModel): void {

        UiBuilder.ClearEmbedToolbar();
        window.powerbi.reset(reportContainer);

        var config: powerbi.IEmbedConfiguration = {
            type: 'report',
            id: viewModel.reportId,
            embedUrl: viewModel.embedUrl + '&language=es&formatLocale=es-HN',
            accessToken: viewModel.token,
            permissions: models.Permissions.All,
            tokenType: models.TokenType.Embed,
            viewMode: models.ViewMode.View,
            settings: {
                panes: {
                    
                    pageNavigation: {
                        visible: true,
                        position: models.PageNavigationPosition.Left,
                    }
                },
                commands: [{ // hide built-in visual commands
                    spotlight: { displayOption: models.CommandDisplayOption.Enabled },
                    exportData: { displayOption: models.CommandDisplayOption.Enabled },
                    seeData: { displayOption: models.CommandDisplayOption.Enabled }
                }],

            }
        };
        if (document.body.clientWidth < 500) {
            config.settings.layoutType = models.LayoutType.MobilePortrait;
            
        }

        var report: powerbi.Report = <powerbi.Report>window.powerbi.embed(reportContainer, config);

        var exportReport = async (ExportType: string) => {

            var bookmarkResult: models.IReportBookmark =
                await report.bookmarksManager.capture({
                    personalizeVisuals: false,
                    allPages: true
                });

            var exportRequest: ExportFileRequest = {
                ExportType: ExportType,
                BookmarkState: bookmarkResult.state
            };
            
            var exportedFileData = await Exporter.ExportFile(exportRequest, window.location.search);
            UiBuilder.DisplayNotification("Descargando el reporte en formato " + ExportType + ", por favor espere unos momentos.", ["btn-exportar-pdf", "btn-exportar-png", "btn-exportar-pptx", "btn-pantallaCompleta", "btn-refrescar"])

        };

        var exportReportPage = async (ExportType: string) => {

            var bookmarkResult: models.IReportBookmark =
                await report.bookmarksManager.capture({
                    personalizeVisuals: false,
                    allPages: false
                });

            //var currentPage: powerbi.Page = await report.getActivePage();
            //var currentPage: powerbi.Page = await report.getPages().then(result => {

            //    var pagina = result.filter(pagina => { return pagina.displayName.includes("ExportLayout")})[0];

            //    return pagina;
            //});

            

            var reportPages: powerbi.Page[] = await report.getPages().then(result => {

                
                var paginas = result.filter(pagina => { return pagina.displayName.includes("ExportLayout") });

                return paginas;
            });
            //var currentPage: powerbi.Page = await report.getPageByName("ExportLayout");
            
            var exportRequest: ExportFileRequest = {
                ExportType: ExportType,
                BookmarkState: bookmarkResult.state,
                //PageName: currentPage.name,
                //VisualName: "ExportLayout"
                Pages: reportPages.map(pagina => pagina.name),
                PageParamsProperties: reportPages.map(pagina => { return { Name: pagina.name, DisplayName: pagina.displayName } })
                
            };

            var exportedFileData = await Exporter.ExportFile(exportRequest, window.location.search);
            UiBuilder.DisplayNotification("Descargando el reporte en formato " + ExportType + ", por favor espere unos momentos.", ["btn-exportar-pdf", "btn-exportar-png", "btn-exportar-pptx", "btn-pantallaCompleta", "btn-refrescar"]);

        }

        var exportReportPageVisual = async (ExportType: string, PageName: string, VisualName: string) => {

            let slicerFilters: string[] = [];
            let currentPage: powerbi.Page = await report.getActivePage();
            let visuals = await currentPage.getVisuals();

            for (let visual of visuals) {
                if (visual.type == "slicer") {
                    let slicerState: any = await visual.getSlicerState();
                    for (let filter of slicerState.filters) {
                        if (filter.operator == "In") {
                            let target = filter.target;
                            let values = filter.values;
                            let tableName = target.table;
                            let columnName = target.column;
                            if (isNaN(Number(values[0]))) {
                                slicerFilters.push(tableName + "/" + columnName + " in ('" + values.join("', '") + "')");
                            }
                            else {
                                slicerFilters.push(tableName + "/" + columnName + " in (" + values.join(", ") + ")");
                            }
                        }
                    }
                }
            }

            var exportRequest: ExportFileRequest = {
                ExportType: ExportType,
                PageName: PageName,
                VisualName: VisualName
            };

            if (slicerFilters.length > 0) {
                exportRequest.Filter = slicerFilters.join(" and ");
            }

            var exportedFileData = await Exporter.ExportFile(exportRequest, window.location.search);
            //TODO: Cambiar o verificar si se puede eliminar
            UiBuilder.DisplayNotification(`Descargando el reporte en formato ${ExportType}, por favor espere unos momentos."`, ["btn-exportar-pdf", "btn-exportar-png", "btn-exportar-pptx", "btn-pantallaCompleta", "btn-refrescar"])

        }

        UiBuilder.AddExportButton("", async () => {
            exportReportPage("pdf");
        }, "L", "bi-file-pdf-fill", "rgb(173,11,0)", "btn-exportar-pdf");

        UiBuilder.AddExportButton("", async () => {
            exportReportPage("pptx");
        }, "L", "bi-filetype-pptx", "rgb(197,72,48)", "btn-exportar-pptx");

        UiBuilder.AddExportButton("", async () => {
            exportReportPage("png");
        }, "L", "bi-file-earmark-bar-graph-fill", "rgb(6,69,104)", "btn-exportar-png");

        
        if (document.body.clientWidth < 500) {
            UiBuilder.AddExportButton("", async () => {
                location.reload();
            }, "R", "bi-arrow-clockwise", "rgb(6, 69, 104)","btn-refrescar");
        }


        if (document.body.clientWidth > 500) {
            UiBuilder.AddCommandButton("Refrescar", async () => {
                location.reload();
            }, "R", "bi-arrow-clockwise", "rgb(6, 69, 104)", "btn-refrescar");
            UiBuilder.AddCommandButton("Pantalla completa", async () => {
                report.fullscreen();
            }, "L", "bi-fullscreen", "rgb(6, 69, 104)", "btn-pantallaCompleta");

        }

    report.on("commandTriggered", async (event: any) => {

      let ExportType: string = event.detail.command;
      let PageName: string = event.detail.page.name;
      let VisualName: string = event.detail.visual.name;

      exportReportPageVisual(ExportType, PageName, VisualName);

    });

  }

  public static EmbedPaginatedReport(reportContainer: HTMLElement, viewModel: ViewModel): void {

    UiBuilder.ClearEmbedToolbar();
    window.powerbi.reset(reportContainer);

    var config: powerbi.IEmbedConfiguration = {
      type: 'report',
      id: viewModel.paginatedReportId,
        embedUrl: viewModel.paginatedEmbedUrl + '&language=es&formatLocale=es-HN',
      accessToken: viewModel.token,
      permissions: models.Permissions.Read,
      tokenType: models.TokenType.Embed,
      viewMode: models.ViewMode.View,
      settings: {}
    };

    var report: powerbi.Report = <powerbi.Report>window.powerbi.embed(reportContainer, config);

  }

  public static EmbedTurduckenReport(reportContainer: HTMLElement, viewModel: ViewModel): void {

    UiBuilder.ClearEmbedToolbar();
    window.powerbi.reset(reportContainer);

    var config: powerbi.IEmbedConfiguration = {
      type: 'report',
      id: viewModel.turduckenReportId,
        embedUrl: viewModel.turduckenEmbedUrl + '&language=es&formatLocale=es-HN', 
      accessToken: viewModel.token,
      permissions: models.Permissions.All,
      tokenType: models.TokenType.Embed,
      viewMode: models.ViewMode.View,
      settings: {}
    }
      ;
    var report: powerbi.Report = <powerbi.Report>window.powerbi.embed(reportContainer, config);

  }

}

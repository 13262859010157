import * as $ from 'jquery';

export class UiBuilder {

  public static DisplayNotification(message: string, buttonIds?: string[]): void {

    const loader = $("#loader").empty().fadeIn("slow");
    $("#notification").empty();

    $("#notification").fadeIn("slow").append(message).append(loader);
    $("#notification").click(function () {
        $("#notification").fadeOut("slow");
        $("#loader").fadeOut("slow");
    });

      (buttonIds) && UiBuilder.SetButtonsState(buttonIds, true)

    }

    public static DisplayError(message: string): void {

        $("#notification").addClass("error").empty();

        $("#notification").fadeIn("slow").append(message);
        
        $("#notification").click(function () {
            $("#notification").removeClass("error").fadeOut("slow");
        });

    }
    public static DismissNotification(buttonIds?: string[]): void {
        $("#notification").removeClass("error").fadeOut("slow");
        (buttonIds) && UiBuilder.SetButtonsState(buttonIds, false)
    }

    public static SetButtonsState(buttonIds: string[], isDisabled: boolean): void {
        buttonIds.forEach(buttonId => {
            if (isDisabled) {
                const button = $(`#${buttonId}`).attr("disabled", "true");
                button.addClass("btn-disabled");
            } else {
                const button = $(`#${buttonId}`).removeAttr("disabled");
                button.removeClass("btn-disabled");
            }
        })
    }

  public static AddTopNavLink(caption: string, command: Function): void {


    var link = $("<a>", { href: "javascript:void(0)" })
      .addClass("nav-link")
      .addClass("text-dark")
      .text(caption)
      .click(() => { command(); });

    var button: JQuery = $("<li>").addClass("nav-item").append(link);

    var toolbar: JQuery = $("#top-nav");
    toolbar.append(button);

  }

    public static AddExportButton(caption: string, command: Function, side: string, icon: string, color: string, id?: string): void {

        var iconTag = $("<i>")
            .addClass("bi")
            //.addClass("mr-1")
            .addClass(icon);

        //var link = $("<a>", { href: "javascript:void(0)" })


        var link = $("<button>")
            .addClass("nav-link")
            .addClass("text-white")
            .addClass("p-1")
            //.addClass("mb-1")
            //.addClass("bg-primary")
            .text(caption)
            .css({
                "background-color": color,
                "font-size": "1.2rem",
                "border-radius": "0px",
                "padding": "0px",
                "min-width": "30px",                
                //"margin": "0px",
                opacity: 1
            }).append(iconTag)
            .click(() => { command(); });

        (id) && (link.attr("id", id))

        var button: JQuery = (side == "L") ?
            $("<li>").addClass("nav-item").append(link) :
            $("<li>").addClass("nav-item").addClass("ml-auto").append(link);

        var toolbar: JQuery = $("#embed-toolbar");
        toolbar.append(button);

    }

  public static AddCommandButton(caption: string, command: Function, side: string, icon: string, color: string, id?: string): void {

    var iconTag = $("<i>")
      .addClass("bi")
      .addClass("ml-1")
      .addClass(icon);

    //var link = $("<a>", { href: "javascript:void(0)" })
    
      
      var link = $("<button>")
      .addClass("nav-link")
      .addClass("text-white")
      .addClass("mb-1")
      //.addClass("bg-primary")
      .text(caption)
      .css({
          "background-color": color,
          //"font-size":"1.5rem",
          "border-radius": "0px",
        
        opacity: 1
      }).append(iconTag)
          .click(() => { command(); });

      (id) && (link.attr("id", id))

    var button: JQuery = (side == "L") ?
      $("<li>").addClass("nav-item").append(link) :
      $("<li>").addClass("nav-item").addClass("ml-auto").append(link);

      var toolbar: JQuery = $("#embed-toolbar").css({"flex-direction":"row !important"});
      toolbar.append(button);

  }

  public static AddLogo(logoUrl: string): void {
    var logo = $("<img>", { src: logoUrl, id: 'img-logo' })

    var header: JQuery = $("header");
    header.append(logo);
  }

  public static ClearEmbedToolbar(): void {
    var toolbar: JQuery = $("#embed-toolbar");
    toolbar.empty();
  }

  private static leftNavigationEnabled: boolean = false;

  public static EnabledLeftNavigation(): void {

    var leftNavWidth: string = "180px";

    $("#embed-container").css({ "margin-left": leftNavWidth });

    var leftNav: JQuery = $("<div>", { id: "left-nav" }).css({ float: "left", width: leftNavWidth });

    var leftNavItemContainer: JQuery = $("<ul>", { id: "left-nav-item-container" }).css({ width: "100%" });

    leftNav.append(leftNavItemContainer);

    $("#content-box").prepend(leftNav);




    this.leftNavigationEnabled = true;

    $("#content-box").css({ width: "100%" });


  }

  public static EnsureLeftNavigation(): void {
    if (!this.leftNavigationEnabled) {
      this.EnabledLeftNavigation();
    }
  }

  public static AddLeftNavigationHeader(caption: string): void {
    this.EnsureLeftNavigation();

    var leftNavHeader: JQuery =
      $("<li>")
        .text(caption).addClass("navbar-header");

    $("#left-nav-item-container").append(leftNavHeader);

  }

  public static AddLeftNavigationItem(caption: string, command: Function): void {
    this.EnsureLeftNavigation();

    var leftNavItem: JQuery =
      $("<li>")
        .addClass("nav-item")
        .append(
          $("<a>", { href: "javascript: void(0);" })
            .text(caption)
            .addClass("nav-link"))
        .click(() => {
          command();
        });

    $("#left-nav-item-container").append(leftNavItem);

  }
}

import * as $ from 'jquery';

import { UiBuilder } from './services/uibuilder'
import { ViewModel, Playbook } from './services/playbook';

var resizeEmbedContainer = () => {
	var heightBuffer = 200;
	var newHeight = $(window).height() - (heightBuffer);
	$("#embed-container").height(newHeight);

};

$(() => {

	var reportContainer: HTMLElement = document.getElementById("embed-container");
	var viewModel: ViewModel = window["viewModel"];

	Playbook.EmbedPowerBiReport(reportContainer, viewModel);


	resizeEmbedContainer();
	$(window).on("resize", resizeEmbedContainer);

});

